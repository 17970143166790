import React from 'react';

function HashoutVirtualTradeshowCasestudy() {
    return (
        <div style={{height: "100vh"}}>
            <object data="../../case-study.pdf#toolbar=0" type="application/pdf" width="100%" height="100%">
                <a href="../../case-study.pdf"></a>
            </object>
        </div>
    )
}

export default HashoutVirtualTradeshowCasestudy
